<template>
  <div>
    <section class="wrapper">
      <row>
        <column
          xs="12"
          sm="2">
          <h1
            :class="['ff-flama fs-xxl fw-light tt-uppercase mt-3', skipRender === true ? 'mb-4' : 'mb-6']"
            v-html="computedPageTitle"></h1>
        </column>
        <div class="text-md-center col-12 col-md-8">
          <LazyHydrate
            v-if="skipRender"
            never>
            <div>
              <h2 class="ff-flama fs-xl fw-light mt-3 mb-4">As a subscriber you will get full access to all epaper editions</h2>
              <div class="wrapper">
                <div class="global-article-selector">
                </div>
              </div>
            </div>
          </LazyHydrate>
        </div>
      </row>
    </section>
    <section
      v-for="edition in editions"
      :key="edition.index"
      :style="skipRender ? 'opacity: 0.25;' : ''"
      class="section_highlight mb-5 pt-5 pb-6 edition-section">
      <div
        :id="sectionIds[edition.title]"
        style="position: absolute; top: -65px;"><!-- A fake placeholder for hash nav --></div>
      <div
        class="wrapper">
        <row>
          <column sm="12">
            <h2 class="ff-sueca fs-xl">{{ edition.title }}</h2>
            <p
              v-html="edition.description"
              class="ff-flama"></p>
            <EditionCarousel
              :items="edition.items"
              :show-title="edition.showTitle"
              :require-login="edition.requireLogin"
              :is-auth-denied="skipRender"/>
          </column>
        </row>
      </div>
    </section>
  </div>
</template>
<script>

import LazyHydrate from 'vue-lazy-hydration';
import { Page, EditionCarousel } from 'global-components';
import editionConfig from 'global-configs/edition';
import { getPaywallMeta } from '../server/core/paywallMetadataProcessor';

const buildPropMeta = (property, content, idx) => ({
  property,
  content,
  idx,
});

export default {
  dataResolver: 'ePaperEditionsPage',
  components: {
    LazyHydrate,
    EditionCarousel,
  },
  extends: Page,
  data() {
    return {
      editions: this.$store.state.editions,
      sectionIds: editionConfig[this.$store.state.publication.code].sectionIds,
      skipRender: true,
    };
  },
  computed: {
    computedPageTitle() {
      return this.$route.meta.pageContext.pageTitle || this.__(this.$route.meta.pageContext.iPageTitle);
    },
  },
  mounted() {
    // window.subPaywallShow gets injected by Zephr if we want to show paywall
    // The code can be found in the component library of Zephr
    this.skipRender = !!window.subPaywallShow;
    console.log(`#GLOBAL-ARTICLE(EDITIONS):: skipRender= "${this.skipRender}", zephrObject= "${JSON.stringify(window.Zephr)}"`);
  },
  embed(head) {
    const addMeta = head.meta.bind(head);
    const paywallMeta = getPaywallMeta(this.$store.state);
    addMeta(buildPropMeta('paywall:is_paid', paywallMeta.isPaid));
    paywallMeta.pids.split(',').map((pid, idx) => addMeta(buildPropMeta('paywall:pids', pid.trim(), idx)));
  }
};
</script>
